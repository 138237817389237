// assets
import Icon from '@ant-design/icons';

import { ReactComponent as SearchIconSvg } from '../icons/LeftToolbar/Search.svg';
import { ReactComponent as ImportIconSvg } from '../icons/LeftToolbar/Import.svg';
import { ReactComponent as MoveIconSvg } from '../icons/LeftToolbar/Move.svg';
import { ReactComponent as RotateIconSvg } from '../icons/LeftToolbar/Rotate.svg';
import { ReactComponent as ModelOnMapIconSvg } from '../icons/LeftToolbar/ModelOnMap.svg';
import { ReactComponent as DimensionsIconSvg } from '../icons/LeftToolbar/Dimensions.svg';
import { ReactComponent as SearchModelIconSvg } from '../icons/LeftToolbar/SearchModel.svg';
import { ReactComponent as MeasureIconSvg } from '../icons/LeftToolbar/Measure.svg';
import { ReactComponent as SunIconSvg } from '../icons/LeftToolbar/Sun.svg';
import { ReactComponent as ScreenshotIconSvg } from '../icons/LeftToolbar/Screenshot.svg';
import { ReactComponent as InactiveCostIconSvg } from '../icons/LeftToolbar/InactiveCost.svg';
import { ReactComponent as InactiveZoningIconSvg } from '../icons/LeftToolbar/InactiveZoning.svg';
import { ReactComponent as InactiveRegulationIconSvg } from '../icons/LeftToolbar/InactiveRegulation.svg';
import { ReactComponent as ContextDataIconSvg } from '../icons/LeftToolbar/ContextData.svg';
import { ReactComponent as SeachMapIconSvg } from '../icons/LeftToolbar/SeachMap.svg';
import { ReactComponent as ArchieIconSvg } from '../icons/LeftToolbar/Archie.svg';
import { ReactComponent as MeasurePerfIconSvg } from '../icons/LeftToolbar/MeasurePerf.svg';

import { ReactComponent as ActiveSearchIconSvg } from '../icons/LeftToolbar/ActiveSearch.svg';
import { ReactComponent as ActiveImportIconSvg } from '../icons/LeftToolbar/ActiveImport.svg';
import { ReactComponent as ActiveMoveIconSvg } from '../icons/LeftToolbar/ActiveMove.svg';
import { ReactComponent as ActiveRotateIconSvg } from '../icons/LeftToolbar/ActiveRotate.svg';
import { ReactComponent as ActiveModelOnMapIconSvg } from '../icons/LeftToolbar/ActiveModelOnMap.svg';
import { ReactComponent as ActiveDimensionsIconSvg } from '../icons/LeftToolbar/ActiveDimensions.svg';
// import { ReactComponent as ActiveSearchModelIconSvg } from '../icons/LeftToolbar/ActiveSearchModel.svg';
import { ReactComponent as ActiveMeasureIconSvg } from '../icons/LeftToolbar/ActiveMeasure.svg';
import { ReactComponent as ActiveSunIconSvg } from '../icons/LeftToolbar/ActiveSun.svg';
import { ReactComponent as ActiveScreenshotIconSvg } from '../icons/LeftToolbar/ActiveScreenshot.svg';
import { ReactComponent as ActiveCostIconSvg } from '../icons/LeftToolbar/ActiveCost.svg';
import { ReactComponent as ActiveZoningIconSvg } from '../icons/LeftToolbar/ActiveZoning.svg';
import { ReactComponent as ActiveRegulationIconSvg } from '../icons/LeftToolbar/ActiveRegulation.svg';
import { ReactComponent as ActiveContextDataIconSvg } from '../icons/LeftToolbar/ActiveContextData.svg';
import { ReactComponent as ActiveSeachMapIconSvg } from '../icons/LeftToolbar/SeachMap.svg';
import { ReactComponent as ActiveArchieIconSvg } from '../icons/LeftToolbar/ActiveArchie.svg';
import { ReactComponent as ActiveMeasurePerfIconSvg } from '../icons/LeftToolbar/ActiveMeasurePerf.svg';

import QuestionsIconPng from '../icons/LeftToolbar/Questions.png';

const PngIcon = (pngComponent, props) => (
    <Icon component={() => <img style={{ transform: 'translateX(-9px)' }} alt="icon" src={pngComponent} />} {...props} />
);
const SvgIcon = (svgComponent, props) => <Icon component={svgComponent} {...props} />;

// icons
const icons = {
    SearchIcon: (props) => SvgIcon(SearchIconSvg, props),
    ImportIcon: (props) => SvgIcon(ImportIconSvg, props),
    MoveIcon: (props) => SvgIcon(MoveIconSvg, props),
    RotateIcon: (props) => SvgIcon(RotateIconSvg, props),
    ModelOnMapIcon: (props) => SvgIcon(ModelOnMapIconSvg, props),
    DimensionsIcon: (props) => SvgIcon(DimensionsIconSvg, props),
    SearchModelIcon: (props) => SvgIcon(SearchModelIconSvg, props),
    MeasureIcon: (props) => SvgIcon(MeasureIconSvg, props),
    SunIcon: (props) => SvgIcon(SunIconSvg, props),
    ScreenshotIcon: (props) => SvgIcon(ScreenshotIconSvg, props),
    InactiveCostIcon: (props) => SvgIcon(InactiveCostIconSvg, props),
    InactiveZoningIcon: (props) => SvgIcon(InactiveZoningIconSvg, props),
    InactiveRegulationIcon: (props) => SvgIcon(InactiveRegulationIconSvg, props),
    ContextDataIcon: (props) => SvgIcon(ContextDataIconSvg, props),
    SeachMapIcon: (props) => SvgIcon(SeachMapIconSvg, props),
    QuestionsIcon: (props) => PngIcon(QuestionsIconPng, props),
    ArchieIcon: (props) => SvgIcon(ArchieIconSvg, props),
    MeasurePerfIcon: (props) => SvgIcon(MeasurePerfIconSvg, props),

    ActiveSearchIcon: (props) => SvgIcon(ActiveSearchIconSvg, props),
    ActiveImportIcon: (props) => SvgIcon(ActiveImportIconSvg, props),
    ActiveMoveIcon: (props) => SvgIcon(ActiveMoveIconSvg, props),
    ActiveRotateIcon: (props) => SvgIcon(ActiveRotateIconSvg, props),
    ActiveModelOnMapIcon: (props) => SvgIcon(ActiveModelOnMapIconSvg, props),
    ActiveDimensionsIcon: (props) => SvgIcon(ActiveDimensionsIconSvg, props),
    ActiveSearchModelIcon: (props) => SvgIcon(SActiveearchModelIconSvg, props),
    ActiveMeasureIcon: (props) => SvgIcon(ActiveMeasureIconSvg, props),
    ActiveSunIcon: (props) => SvgIcon(ActiveSunIconSvg, props),
    ActiveScreenshotIcon: (props) => SvgIcon(ActiveScreenshotIconSvg, props),
    ActiveCostIcon: (props) => SvgIcon(ActiveCostIconSvg, props),
    ActiveZoningIcon: (props) => SvgIcon(ActiveZoningIconSvg, props),
    ActiveRegulationIcon: (props) => SvgIcon(ActiveRegulationIconSvg, props),
    ActiveContextDataIcon: (props) => SvgIcon(ActiveContextDataIconSvg, props),
    ActiveSeachMapIcon: (props) => SvgIcon(ActiveSeachMapIconSvg, props),
    ActiveQuestionsIcon: (props) => PngIcon(ActiveQuestionsIconPng, props),
    ActiveArchieIcon: (props) => SvgIcon(ActiveArchieIconSvg, props),
    ActiveMeasurePerfIcon: (props) => SvgIcon(ActiveMeasurePerfIconSvg, props)
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
    id: 'group-dashboard',
    title: '',
    type: 'group',
    children: [
        {
            id: 'search',
            title: '',
            type: 'item',
            url: '#',
            // icon: icons.SearchIcon,
            // activeIcon: icons.ActiveSearchIcon,
            breadcrumbs: false
        },
        {
            id: 'spacer1',
            title: '',
            type: 'spacer',
            url: '#',
            space: 1,
            breadcrumbs: false
        },
        {
            type: 'divider',
            id: 'divider0'
        },
        {
            id: 'models',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.ImportIcon,
            activeIcon: icons.ActiveImportIcon,
            showTooltip: "Upload a revit model. Once model is uploaded, you'll find it in the Tasks area",
            breadcrumbs: false
        },
        {
            id: 'modelOnMap',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.ModelOnMapIcon,
            activeIcon: icons.ActiveModelOnMapIcon,
            showTooltip: (
                <div>
                    <p>
                        Press <b>Shift</b> and <b>Click</b> on map to put vertices of selection.
                    </p>
                    <p> Drag across the map area to select area of interest </p>
                    <p>
                        Once the area is selected, press <b>Shift</b> and <b>Right-Click</b> to finish selection.
                    </p>
                    <p>
                        To cancel selection, press <b>Shift</b> and <b>Double-Click</b>
                    </p>
                </div>
            ),
            breadcrumbs: false
        },
        {
            id: 'move',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.MoveIcon,
            activeIcon: icons.ActiveMoveIcon,
            showTooltip: (
                <div>
                    <p>First select an area of interest. Then select a model from task list.</p>
                    <p> Then use this transformation wizard on the model </p>
                </div>
            ),
            breadcrumbs: false
        },
        {
            type: 'divider',
            id: 'divider1'
        },
        // {
        //     id: 'dimensions',
        //     title: '',
        //     type: 'item',
        //     url: '#',
        //     icon: icons.DimensionsIcon,
        //     activeIcon: icons.ActiveDimensionsIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'searchModel',
        //     title: '',
        //     type: 'item',
        //     url: '#',
        //     icon: icons.SearchModelIcon,
        //     activeIcon: icons.ActiveSearchModelIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'measure',
        //     title: '',
        //     type: 'item',
        //     url: '#',
        //     icon: icons.MeasureIcon,
        //     activeIcon: icons.ActiveMeasureIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'sun',
        //     title: '',
        //     type: 'item',
        //     url: '#',
        //     icon: icons.SunIcon,
        //     activeIcon: icons.ActiveSunIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'screenshot',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.ScreenshotIcon,
            activeIcon: icons.ActiveScreenshotIcon,
            showTooltip: (
                <div>
                    <p>First select an area of interest. Then select a model from task list.</p>
                    <p> You can then interact with the scene by searching for places of interest </p>
                    <p> While working on the scene you can save your current work by clicking this </p>
                    <p> Your scene will saved in your community in draft state</p>
                </div>
            ),
            breadcrumbs: false
        },
        {
            id: 'cost',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.InactiveCostIcon,
            activeIcon: icons.ActiveCostIcon,
            showTooltip: (
                <div>
                    <p>First create or load a scene. Then cost analysis can be performed.</p>
                    <p>Show Cost as Bill of Quantities of construction at current site</p>
                </div>
            ),
            breadcrumbs: false
        },
        {
            id: 'zoning',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.InactiveZoningIcon,
            activeIcon: icons.ActiveZoningIcon,
            showTooltip: (
                <div>
                    <p>First create or load a scene. Then zoning analysis can be performed.</p>
                    <p>Show Zoning regulations in tabular format at current site</p>
                </div>
            ),
            breadcrumbs: false
        },
        {
            id: 'regulation',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.InactiveRegulationIcon,
            activeIcon: icons.ActiveRegulationIcon,
            showTooltip: (
                <div>
                    <p>First create or load a scene. Then zoning analysis can be performed.</p>
                    <p>Show Zoning regulations in tabular format at current site</p>
                </div>
            ),
            breadcrumbs: false
        },
        // {
        //     id: 'measurePerf',
        //     title: '',
        //     type: 'item',
        //     url: '#',
        //     icon: icons.MeasurePerfIcon,
        //     activeIcon: icons.ActiveMeasurePerfIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'contextData',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.ContextDataIcon,
            activeIcon: icons.ActiveContextDataIcon,
            showTooltip: (
                <div>
                    <p>First select an area of interest. Then select a model from task list.</p>
                    <p> You can then use this to interact with the scene by searching for places of interest </p>
                    <p> For eg. you can search for hospitals in 5 mile radius </p>
                </div>
            ),
            breadcrumbs: false
        },
        // {
        //     id: 'searchMap',
        //     title: '',
        //     type: 'item',
        //     url: '#',
        //     icon: icons.SeachMapIcon,
        //     activeIcon: icons.ActiveSeachMapIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'questions',
        //     title: '',
        //     type: 'item',
        //     url: '#',
        //     iconType: 'png',
        //     icon: icons.QuestionsIcon(),
        //     breadcrumbs: false
        // },
        {
            id: 'archie',
            title: '',
            type: 'item',
            url: '#',
            icon: icons.ArchieIcon,
            activeIcon: icons.ActiveArchieIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
