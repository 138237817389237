import * as React from 'react';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import AliceCarousel from 'react-alice-carousel';
import Cookies from 'js-cookie';
import 'react-alice-carousel/lib/alice-carousel.css';
// import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = SwipeableViews;

const SceneTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9'
    }
}));

function createGrid(rectangleHalfSize) {
    const gridWidth = 41;
    const gridHeight = 41;
    const everestLatitude = Cesium.Math.toRadians(51.51786);
    const everestLongitude = Cesium.Math.toRadians(-0.102216);
    const e = new Cesium.Rectangle(
        everestLongitude - rectangleHalfSize,
        everestLatitude - rectangleHalfSize,
        everestLongitude + rectangleHalfSize,
        everestLatitude + rectangleHalfSize
    );
    const terrainSamplePositions = [];
    for (let y = 0; y < gridHeight; ++y) {
        for (let x = 0; x < gridWidth; ++x) {
            const longitude = Cesium.Math.lerp(e.west, e.east, x / (gridWidth - 1));
            const latitude = Cesium.Math.lerp(e.south, e.north, y / (gridHeight - 1));
            const position = new Cesium.Cartographic(longitude, latitude);
            terrainSamplePositions.push(position);
        }
    }
    return terrainSamplePositions;
}

const sceneItems = (sceneType, selectedFolder, sceneList) => {
    const allScenes = sceneList.map(
        (scene, index) =>
            (selectedFolder === 'All' || (scene.folders && scene.folders.indexOf(selectedFolder) >= 0)) &&
            (sceneType === 'All Scenes' ||
                (scene.permission && scene.permission === 'draft' && sceneType === 'Drafts') ||
                (scene.permission && scene.permission === 'public' && sceneType === 'Completed')) && (
                <div
                    key={scene.scene_id}
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                        width: '75px',
                        marginLeft: '5px',
                        marginRight: '5px',
                        cursor: 'pointer'
                    }}
                    className="item"
                    data-value="1"
                >
                    {!scene.local_glb_uri && (
                        <div
                            style={{
                                border: '2px solid rgb(243 111 75 / 56%)',
                                background: '#5c5a5a 50% / cover no-repeat',
                                borderRadius: '10px',
                                width: '75px',
                                height: '50px',
                                display: 'grid',
                                placeContent: 'center'
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    {scene.local_glb_uri && (
                        <SceneTooltip
                            TransitionComponent={Zoom}
                            placement="bottom"
                            sx={{
                                width: '200px'
                            }}
                            title={
                                <React.Fragment>
                                    <Typography color="inherit" style={{ fontSize: '12px' }}>
                                        <b>Scene id</b> :{scene.scene_id}
                                    </Typography>
                                    <Typography color="inherit" style={{ fontSize: '12px' }}>
                                        <b>Name</b> :{scene.name ? scene.name : ' '}
                                    </Typography>
                                    <Typography color="inherit" style={{ fontSize: '12px' }}>
                                        <b>Coordinates</b>: ({scene.latitude}, {scene.longitude})
                                    </Typography>
                                    <Typography color="inherit" style={{ fontSize: '12px' }}>
                                        <b>Location</b>: {scene.placeName}
                                    </Typography>
                                </React.Fragment>
                            }
                            arrow
                        >
                            {!scene.name ? (
                                <model-viewer
                                    style={{
                                        border: '2px solid rgb(243 111 75 / 56%)',
                                        background: '#5c5a5a 50% / cover no-repeat',
                                        borderRadius: '10px',
                                        width: '75px',
                                        height: '50px'
                                    }}
                                    ondragstart={() => {
                                        drag(event);
                                    }}
                                    src={`${scene.local_glb_uri}`}
                                    poster={`${'https://dtuas2sql0dg4.cloudfront.net/build/hoverToLoad.png'}`}
                                    reveal="manual"
                                    data-value={JSON.stringify(scene)}
                                    onMouseOver={(ev) => {
                                        ev.target.poster = '';
                                        ev.target.dismissPoster();
                                    }}
                                    onMouseLeave={(ev) => {
                                        ev.target.poster = 'https://dtuas2sql0dg4.cloudfront.net/build/hoverToLoad.png';
                                    }}
                                    onClick={(ev) => {
                                        if (window.applyGlobeSpinEvent) {
                                            window.applyGlobeSpinEvent();
                                        }
                                        viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                        const dataValue = JSON.parse(ev.target.getAttribute('data-value'));
                                        window.sceneCreateOrUpdate = 'update';
                                        viewer.entities.removeAll();
                                        loadScene(dataValue);
                                        window.sceneToUpdate = ev.target.getAttribute('data-value');
                                        window.addScene();
                                    }}
                                />
                            ) : (
                                <div
                                    role="button"
                                    tabIndex={0}
                                    style={{
                                        border: '2px solid rgb(243 111 75 / 56%)',
                                        background: '#5c5a5a 50% / cover no-repeat',
                                        borderRadius: '10px',
                                        width: '75px',
                                        height: '50px',
                                        backgroundImage: `url('${scene.local_thumbnail_uri}')`
                                    }}
                                    ondragstart={() => {
                                        drag(event);
                                    }}
                                    data-value={JSON.stringify(scene)}
                                    onClick={(ev) => {
                                        if (window.applyGlobeSpinEvent) {
                                            window.applyGlobeSpinEvent();
                                        }
                                        viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                        const dataValue = JSON.parse(ev.target.getAttribute('data-value'));
                                        window.sceneCreateOrUpdate = 'update';
                                        loadScene(dataValue);
                                        window.sceneToUpdate = ev.target.getAttribute('data-value');
                                        window.addScene();
                                    }}
                                    onKeyDown={(ev) => {
                                        if (window.applyGlobeSpinEvent) {
                                            window.applyGlobeSpinEvent();
                                        }
                                        viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                        const dataValue = JSON.parse(ev.target.getAttribute('data-value'));
                                        window.sceneCreateOrUpdate = 'update';
                                        viewer.entities.removeAll();
                                        loadScene(dataValue);
                                        window.sceneToUpdate = ev.target.getAttribute('data-value');
                                        window.addScene();
                                    }}
                                />
                            )}
                        </SceneTooltip>
                    )}
                </div>
            )
    );
    // allScenes.push(
    //     <button
    //         style={{ display: 'grid', placeItems: 'center', width: '60px', border: 'none', backgroundColor: '#363c50', cursor: 'pointer' }}
    //         className="item"
    //         data-value="5"
    //         onClick={() => {
    //             window.sceneCreateOrUpdate = 'create';
    //             window.addScene();
    //         }}
    //     >
    //         <img
    //             style={{ border: '2px solid grey', borderRadius: '10px', width: '75px', height: '50px' }}
    //             alt="Serbia"
    //             src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/NewScene.png"
    //         />
    //     </button>
    // );
    return allScenes;
};

window.saveScene = (sceneData, isNewScene) => {
    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';
    if (isNewScene) {
        return new Promise((resolve, reject) => {
            fetch('https://dev.thetwinmaster.com/twinworld/createScene', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(sceneData)
            })
                .then((res) => res.json())
                .then((sceneResponse) => {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                    resolve(sceneResponse);
                });
        });
    } else {
        return new Promise((resolve, reject) => {
            fetch('https://dev.thetwinmaster.com/twinworld/updateScene', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: 'include',
                body: JSON.stringify(sceneData)
            })
                .then((res) => res.text())
                .then((sceneId) => {
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                    resolve(sceneId);
                });
        });
    }
};

const responsive = {
    0: { items: 1 },
    268: { items: 3, itemsFit: 'contain' },
    1024: { items: 3, itemsFit: 'contain' }
};

var BASE_GRAPHQL_URL = 'https://dev.thetwinmaster.com/sim';

const loadBuildingScene = (scene) => {
    document.querySelector('twinworld-app').shadowRoot.querySelector('main').innerHTML = `<model-viewer
        style="
            border: 2px solid rgb(243 111 75 / 56%);
            background: #363C4F 100% / cover no-repeat;
            width: calc(100vw - 64px);
            height: calc(100vh - 104px);
            margin-top: -24px;
        "
        src="${scene.local_glb_uri}"
        ar
        shadow-intensity="1" camera-controls touch-action="pan-y"
    />`;
};

window.loadScene = (scene) => {
    const dataValue = Object.assign({}, scene);
    window.destBoundingBoxStr = JSON.parse(dataValue.boundingBox);
    window.destBoundingBox = new Cesium.Rectangle(
        window.destBoundingBoxStr.west,
        window.destBoundingBoxStr.south,
        window.destBoundingBoxStr.east,
        window.destBoundingBoxStr.north
    );
    window.destCenter = Cesium.Rectangle.center(window.destBoundingBox);
    let latLonCoords;
    if (dataValue.description) {
        const descriptionWithAccurateLatLon = dataValue.description.split(/(,|\(|\))/g);
        latLonCoords = {
            latitude: parseFloat(descriptionWithAccurateLatLon[2]),
            longitude: parseFloat(descriptionWithAccurateLatLon[4])
        };
    } else {
        latLonCoords = {
            latitude: (parseFloat(dataValue.latitude) * Cesium.Math.PI) / 180,
            longitude: (parseFloat(dataValue.longitude) * Cesium.Math.PI) / 180
        };
    }

    const latLonCoordsDegree = {
        latitude: (latLonCoords.latitude * 180) / Cesium.Math.PI,
        longitude: (latLonCoords.longitude * 180) / Cesium.Math.PI
    };

    window.worldSceneId = dataValue.worldSceneId;

    const height = parseFloat(dataValue.height);
    window.destinationSeaLevelHeight = height;

    const rotation = dataValue.rotation
        ? typeof dataValue.rotation === 'string'
            ? JSON.parse(dataValue.rotation)
            : dataValue.rotation.length === 4
            ? { x: dataValue.rotation[0], y: dataValue.rotation[1], z: dataValue.rotation[2], w: dataValue.rotation[3] }
            : dataValue.rotation
        : null;

    const scale = dataValue.scale ? parseFloat(dataValue.scale) : null;

    const resJson = dataValue.pois ? (typeof dataValue.pois === 'string' ? JSON.parse(dataValue.pois) : dataValue.pois) : [];
    window.allPickedAssets = resJson;
    window.entityList = Array.from(
        new Set(
            resJson
                .filter((pickedAsset) => {
                    return pickedAsset.name && !pickedAsset.name.endsWith('undefined )');
                })
                .filter((pickedAsset) => pickedAsset.name !== window.currentAssetName)
                .map((pickedAsset) => (
                    <span>
                        {pickedAsset.name.split('( Status')[0]}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {'    ( Status' + pickedAsset.name.split('( Status')[1]}
                        <br />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lat: {parseFloat(pickedAsset.latitude).toFixed(3)}&nbsp; Lon:{' '}
                        {parseFloat(pickedAsset.longitude).toFixed(3)}
                    </span>
                ))
        )
    );
    if ((dataValue.cameraDestination && dataValue.cameraOrientation) || dataValue.cameraPosition) {
        const cameraPosition = dataValue.cameraDestination
            ? {
                  destination: {
                      x: dataValue.cameraDestination[0],
                      y: dataValue.cameraDestination[1],
                      z: dataValue.cameraDestination[2]
                  },
                  orientation: {
                      heading: dataValue.cameraOrientation[0],
                      pitch: dataValue.cameraOrientation[1],
                      roll: dataValue.cameraOrientation[2]
                  }
              }
            : JSON.parse(dataValue.cameraPosition);
        if (cameraPosition.destination && cameraPosition.orientation.heading) {
            viewer.camera.flyTo({
                destination: cameraPosition.destination,
                orientation: {
                    heading: cameraPosition.orientation.heading - 0.001,
                    pitch: cameraPosition.orientation.pitch,
                    roll: cameraPosition.orientation.roll
                }
            });
            setTimeout(() => {
                viewer.camera.flyTo({
                    destination: cameraPosition.destination,
                    orientation: {
                        heading: cameraPosition.orientation.heading - 0.002,
                        pitch: cameraPosition.orientation.pitch,
                        roll: cameraPosition.orientation.roll
                    }
                });
            }, 2500);
        }
        if (height) {
            const thisSceneDestinationCartographic = new Cesium.Cartographic.fromRadians(
                latLonCoords.longitude,
                latLonCoords.latitude,
                height
            );
            // window.createModel(dataValue.buildingModel, Cesium.Cartographic.toCartesian(thisSceneDestinationCartographic), scale, rotation);
            /*viewer.camera.flyTo({
                destination: Cesium.Ellipsoid.WGS84.cartographicToCartesian(
                    new Cesium.Cartographic(latLonCoords.longitude, latLonCoords.latitude, height)
                ),
                orientation: {
                    heading: 0.34618667513190715,
                    pitch: -0.6108646914671176,
                    roll: 6.283185299423947
                }
            });*/
            setTimeout(() => {
                window.destSelectedCenter = {
                    latitude: latLonCoords.latitude,
                    longitude: latLonCoords.longitude,
                    height: height
                };
                if (dataValue.buildingModel) {
                    window.dropNamedAsset(dataValue.buildingModel);
                } else if (dataValue.local_glb_uri) {
                    window.dropNamedAsset(dataValue.local_glb_uri);
                } else {
                    console.error('FAILED TO FIND model to drop in scene. Here cameraPosition is defined.');
                }
                setTimeout(() => {
                    if (scale) {
                        // alert(`Building being scaled to : ${scale}`);
                        if (window.drone) {
                            window.drone.model.scale = scale;
                        }
                    }
                    if (rotation) {
                        if (window.drone) {
                            window.drone.orientation = rotation;
                        }
                    }
                    if (cameraPosition.destination && cameraPosition.orientation.heading) {
                        setTimeout(() => {
                            viewer.scene.mode = Cesium.SceneMode.SCENE3D;
                            document.querySelector('twinworld-app').shadowRoot.querySelector('.compass').style.right = '100px';
                            document.querySelector('twinworld-app').shadowRoot.querySelector('.navigation-controls').style.right = '130px';
                            setTimeout(() => {
                                viewer.camera.flyTo({
                                    destination: cameraPosition.destination,
                                    orientation: {
                                        heading: cameraPosition.orientation.heading - 0.001,
                                        pitch: cameraPosition.orientation.pitch,
                                        roll: cameraPosition.orientation.roll
                                    }
                                });
                            }, 5000);
                        }, 5000);
                    }
                }, 2000);
            }, 6000);
        }
    } else {
        if (height) {
            const thisSceneDestinationCartographic = new Cesium.Cartographic.fromRadians(
                latLonCoords.longitude,
                latLonCoords.latitude,
                height
            );
            // window.createModel(dataValue.buildingModel, Cesium.Cartographic.toCartesian(thisSceneDestinationCartographic), scale, rotation);
            setTimeout(() => {
                /*viewer.camera.flyTo({
                    destination: Cesium.Ellipsoid.WGS84.cartographicToCartesian(
                        new Cesium.Cartographic(latLonCoords.longitude, latLonCoords.latitude, height)
                    ),
                    orientation: {
                        heading: 0.34618667513190715,
                        pitch: -0.6108646914671176,
                        roll: 6.283185299423947
                    }
                });*/
            }, 1500);
            setTimeout(() => {
                window.destSelectedCenter = {
                    latitude: latLonCoords.latitude,
                    longitude: latLonCoords.longitude,
                    height: height
                };
                if (dataValue.buildingModel) {
                    window.dropNamedAsset(dataValue.buildingModel);
                } else if (dataValue.local_glb_uri) {
                    window.dropNamedAsset(dataValue.local_glb_uri);
                } else {
                    console.error('FAILED TO FIND model to drop in scene. Here cameraPosition is defined.');
                }
                setTimeout(() => {
                    if (scale) {
                        // alert(`Building being scaled to : ${scale}`);
                        if (window.drone) {
                            window.drone.model.scale = scale;
                        }
                    }
                    if (rotation) {
                        if (window.drone) {
                            window.drone.orientation = rotation;
                        }
                    }
                    /*if (viewer.scene.camera.position) {
                        viewer.camera.flyTo({
                            destination: viewer.scene.camera.position,
                            orientation: {
                                heading: viewer.camera.heading - 0.001,
                                pitch: viewer.camera.pitch,
                                roll: viewer.camera.roll
                            }
                        });
                    }*/
                    viewer.camera.flyTo({
                        destination: Cesium.Ellipsoid.WGS84.cartographicToCartesian(
                            new Cesium.Cartographic(latLonCoords.longitude, latLonCoords.latitude, height + 1000)
                        ),
                        orientation: {
                            heading: 0.34618667513190715,
                            pitch: -0.6108646914671176,
                            roll: 6.283185299423947
                        }
                    });
                }, 2000);
            }, 2000);
        } else {
            setTimeout(() => {
                axios
                    .get(
                        `https://dev.thetwinmaster.com/twinworld/elevation?locations=${(latLonCoords.latitude * 180) / Math.PI},${
                            (latLonCoords.longitude * 180) / Math.PI
                        }`
                    )
                    .then((response) => {
                        const elevation = response.data.results[0].elevation + 45;
                        if (dataValue.buildingModel) {
                            //window.dropNamedAsset(`/twinworld/models/${dataValue.buildingModel}`, latLonCoords, elevation);

                            window.createModel(
                                `/twinworld/models/${dataValue.buildingModel}`,
                                Cesium.Cartographic.toCartesian(
                                    new Cesium.Cartographic.fromRadians(
                                        parseFloat(latLonCoords.longitude),
                                        parseFloat(latLonCoords.latitude),
                                        parseFloat(elevation)
                                    )
                                ),
                                scale,
                                rotation
                            );
                        }
                        viewer.camera.flyTo({
                            destination: Cesium.Ellipsoid.WGS84.cartographicToCartesian(
                                new Cesium.Cartographic(latLonCoords.longitude, latLonCoords.latitude, elevation)
                            ),
                            orientation: {
                                heading: 0.34618667513190715,
                                pitch: -0.6108646914671176,
                                roll: 6.283185299423947
                            }
                        });
                    });
            }, 2000);
        }

        setTimeout(() => {
            if (window.entityList.length === 0) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility = 'hidden';
            }
            document.querySelector('twinworld-app').shadowRoot.querySelector('#navigationDiv').style.transform = 'translateX(-60px)';
        }, 1000);
    }
    window.scanState = 'complete';
    if (document.querySelector('twinworld-app').shadowRoot.getElementById('contextData')) {
        document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
    }

    setTimeout(() => {
        if (document.querySelector('twinworld-app').shadowRoot.getElementById('contextData')) {
            document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
        }
        // document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'block';
        // document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';
        // document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
    }, 20);
    const showSceneDetails = () => {
        window.poiFlagPoints = [];
        resJson
            .filter((data) => {
                return !data.name.endsWith('undefined )');
            })
            .forEach((formattedPoi, index) => {
                var flag = new Cesium.PinBuilder();
                var flagProperties = new Cesium.PropertyBag();
                flagProperties.addProperty('MyProperty_A', 0);
                flagProperties.addProperty('MyProperty_B', 1);
                // Create an Entity
                var flagPoint = viewer.entities.add({
                    name: 'pole',
                    position: Cesium.Cartesian3.fromDegrees(
                        formattedPoi.longitude,
                        formattedPoi.latitude,
                        window.destinationSeaLevelHeight
                    ),
                    billboard: {
                        image: flag.fromUrl(formattedPoi.pinIcon, new Cesium.Color.fromCssColorString('#1e90ff99'), 50),
                        // image: flag.fromUrl(formattedPoi.pinIcon, Cesium.Color.DODGERBLUE, 50),
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                    },
                    properties: flagProperties
                });
                window.poiFlagPoints.push(flagPoint);
                var flagNumPoint = viewer.entities.add({
                    name: 'pole',
                    position: Cesium.Cartesian3.fromDegrees(
                        formattedPoi.longitude,
                        formattedPoi.latitude,
                        window.destinationSeaLevelHeight
                    ),
                    billboard: {
                        image: flag.fromText(index + 1, Cesium.Color.DODGERBLUE, 25).toDataURL(),
                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                    },
                    properties: flagProperties
                });
                window.poiFlagPoints.push(flagNumPoint);
            });
        /*if (cameraPosition) {
            viewer.camera.flyTo({
                destination: cameraPosition.destination,
                orientation: {
                    heading: cameraPosition.orientation.heading - 0.0001,
                    pitch: cameraPosition.orientation.pitch,
                    roll: cameraPosition.orientation.roll
                }
            });
        }*/
    };
    if (!window.twinWorldSceneList) {
        setTimeout(() => {
            showSceneDetails();
        }, 5000);
    } else {
        setTimeout(() => {
            showSceneDetails();
        }, 3000);
    }

    try {
        window.destSelectedCenter = {
            latitude: parseFloat(dataValue.latitude),
            longitude: parseFloat(dataValue.longitude),
            height: height
        };
        window.activeOption = 'contextData';
        /*
        window.createModel(
            dataValue.local_glb_uri,
            Cesium.Cartographic.toCartesian(
                new Cesium.Cartographic.fromDegrees(
                    parseFloat(dataValue.longitude),
                    parseFloat(dataValue.latitude),
                    parseFloat(dataValue.height)
                )
            ),
            scale,
            rotation
        );*/
    } catch (err) {
        console.log(err);
        console.log('Failed to drop asset', err);
    }
};

function Scenes() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [taskList, setTaskList] = React.useState([]);
    const [sceneList, setSceneList] = React.useState([]);
    const [selectedFolder, setSelectedFolder] = React.useState('All');
    const [sceneType, setSceneType] = React.useState('All Scenes');
    const [sceneItemsDom, setSceneItemsDom] = React.useState([]);
    const [receivedMessage, setReceivedMessage] = React.useState('');
    const [searchTerm, setSearchTerm] = React.useState('');
    const [twinupCookie] = React.useState(Cookies.get('dev-twinup'));
    const [showLabel, setShowLabel] = React.useState(false);

    const getSceneTwinData = (accessToken, sceneId) => {
        return axios
            .get(`https://dev.thetwinmaster.com/api/v1/twin/world/${sceneId}`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then((response) => {
                return {
                    folders: response.data.folders,
                    permission: response.data.permission
                };
            });
    };

    const buildingDetails = (accessToken, buildingGuid, buildingS3Location, structuralSystemS3Location) => {
        console.log(`building ID: ${buildingGuid}`);

        const url = `${BASE_GRAPHQL_URL}`;
        const variables = {};
        const query = `
        query {
            Building(id: "${buildingGuid}") {
                id
                name
                s3location
                StructuralSystem{ 
                    s3location
                }
            }
        }`;

        if (structuralSystemS3Location || buildingS3Location) {
            return new Promise((resolve, reject) => {
                resolve({
                    data: {
                        Building: [
                            {
                                s3location: buildingS3Location,
                                StructuralSystem: {
                                    s3location: structuralSystemS3Location
                                }
                            }
                        ]
                    }
                });
            });
        } else {
            return axios
                .post(
                    url,
                    {
                        query,
                        variables
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            buildingid: accessToken
                        }
                    }
                )
                .then((response) => response.data);
        }
    };

    const getSceneTwin = (accessToken, sceneId) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`https://dev.thetwinmaster.com/api/v1/twin/world/${sceneId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    const twinRes = response.data;
                    const twinImages = twinRes.images;
                    if (twinImages && twinImages.length > 0) {
                        resolve({
                            image: twinImages[0].s3_url,
                            name: twinRes.name
                        });
                    } else {
                        resolve({
                            image: null,
                            name: null
                        });
                    }
                })
                .catch((err) => {
                    console.error('Failed to retrieve twin image');
                    resolve({
                        image: null,
                        name: null
                    });
                });
        });
    };

    const getBuildingUriGlb = (buildingId, buildingUriGlb, buildingUriThumbnail) => {
        // console.log(`callAPI with ${uri_glb}...`);
        const parseS3Url = require('parse-aws-s3-url');
        const parts = parseS3Url(buildingUriGlb);
        const thumbnailParts =
            buildingUriThumbnail && buildingUriThumbnail !== ''
                ? parseS3Url(buildingUriThumbnail)
                : {
                      Bucket: '',
                      Key: ''
                  };

        //=> parts = {Bucket: 'my-bucket', Key: 'path/to/my/file.png'}
        const bucket = parts.Bucket;
        const key = parts.Key;
        const thumbnailBucket = thumbnailParts.Bucket;
        const thumbnailKey = thumbnailParts.Key;

        return fetch(
            `https://dev.thetwinmaster.com/twinworld/getGlbModel?bucket=${bucket}&s3_key=${key}&thumbnail_bucket=${thumbnailBucket}&s3_thumbnail_key=${thumbnailKey}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    // console.log(`Success: calling download_glb API with result ${JSON.stringify(result, null, 4)}`);
                    // console.log(`Result: ${JSON.stringify(result, null, 4)}`);
                    const glb_path = result.glb_path.replace('./utils', 'https://dev.thetwinmaster.com/community/twinworld/utils');
                    const thumbnail_path =
                        result.thumbnail_path && result.thumbnail_path !== ''
                            ? result.thumbnail_path.replace('./utils', 'https://dev.thetwinmaster.com/community/twinworld/utils')
                            : '';
                    // console.log(`GLB Path: ${JSON.stringify(glb_path)}`);
                    return {
                        glb: glb_path,
                        thumbnail: thumbnail_path
                    };
                },
                (error) => {
                    console.log(`Error: calling download_glb API with message ${error}`);
                }
            );
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const setMessage = (message, resolveRes) => {
        // mutation.target.setAttribute('search_response', message);
        setReceivedMessage(message);
        resolveRes(message);
    };

    window.updateTotal = (category) => {
        const rows = document
            .querySelector('twinworld-app')
            .shadowRoot.querySelectorAll(`.expandable[data-category="${category}"] .unit-cost`);
        let categoryTotal = 0;
        rows.forEach((cell) => {
            const value = parseFloat(cell.textContent.replace(/[^0-9.-]+/g, ''));
            if (!isNaN(value)) {
                categoryTotal += value;
            }
        });
        const categoryTotalCell = document.querySelector('twinworld-app').shadowRoot.getElementById(`${category}-total-cost`);
        const groupCategoryTotal = document.querySelector('twinworld-app').shadowRoot.querySelector(`.${category}-total-amount`);
        categoryTotalCell.textContent = `$${categoryTotal.toLocaleString()}`;
        groupCategoryTotal.textContent = `$${categoryTotal.toLocaleString()}`;

        const allRows = document.querySelector('twinworld-app').shadowRoot.querySelectorAll(`.expandable[data-category] .unit-cost`);
        let finalTotal = 0;
        allRows.forEach((cell) => {
            const value = parseFloat(cell.textContent.replace(/[^0-9.-]+/g, ''));
            if (!isNaN(value)) {
                finalTotal += value;
            }
        });

        const finalTotalBox = document.querySelector('twinworld-app').shadowRoot.querySelector('.amount-total-value');
        finalTotalBox.textContent = finalTotal;
    };

    window.toggleVisibility = (category, element) => {
        //Hide all rows
        const allRows = document.querySelector('twinworld-app').shadowRoot.querySelectorAll(`.expandable[data-category]`);
        allRows.forEach((row) => {
            row.classList.remove('show');
        });

        const rows = document.querySelector('twinworld-app').shadowRoot.querySelectorAll(`.expandable[data-category="${category}"]`);
        rows.forEach((row) => {
            row.classList.add('show');
        });

        updateTotal(category);
    };

    window.updateNetCost = (inputElement, category) => {
        const row = inputElement.closest('tr');
        const rate = parseFloat(row.querySelector('.rate').value) || 0;
        const percent = row.querySelector('.percent');
        const quantity = parseFloat(row.querySelector('.quantity').value) || 1;
        const unitCostCell = row.querySelector('.unit-cost');

        // Calculate new unit cost
        const netCost = percent ? (rate * quantity) / 100 : rate * quantity;
        unitCostCell.textContent = `$${netCost.toLocaleString()}`;

        // Update total for the category
        updateTotal(category);
    };

    const convertDataToCostTable = (dataArray) => {
        // Object to store data by category
        const dataByCategory = {};

        // Skip the first and last rows, as they are empty
        for (let i = 2; i < dataArray.length - 1; i++) {
            const row = dataArray[i];

            // If the row is the header row, skip it
            if (row[0] === 'Cost Category') {
                continue;
            }

            const category = row[0];
            const element = row[1];
            const rate = row[2];
            const quantity = row[3];
            const unitType = row[4];
            const netCost = row[5];
            const remarks = row[6];

            // Add data to the corresponding category
            if (!dataByCategory[category]) {
                dataByCategory[category] = [];
            }

            dataByCategory[category].push({
                element,
                rate,
                quantity,
                unitType,
                netCost,
                remarks
            });
        }

        // Create the new HTML structure
        let newTableHtml = `
        <table class='csv-data boq-details'>
            <thead>
                <tr>
                    <th>Cost Element</th>
                    <th>Rate (per unit)</th>
                    <th>Quantity</th>
                    <th>Unit Type</th>
                    <th>Net Cost</th>
                    <th>Remarks</th>
                </tr>
            </thead>
            <tbody>`;

        // Loop over each category to build the rows
        Object.keys(dataByCategory).forEach((category) => {
            //toggleVisibility('${category
            //            .toLowerCase()
            //            .replace(/\s+|,|\(|\)/g, '-')}', this)"

            // Add expandable rows for each item in the category
            dataByCategory[category].forEach((item) => {
                newTableHtml += `
                <tr class="expandable" data-category="${category.toLowerCase().replace(/\s+|,|\(|\)/g, '-')}">
                    <td class="indent">${item.element}</td>
                    <td>
                        ${
                            item.rate.endsWith('%')
                                ? `<span class="percent">${item.rate}</span>`
                                : `<input width="" type="number" class="rate" value="${item.rate}" oninput="updateNetCost(this, '${category
                                      .toLowerCase()
                                      .replace(/\s+|,|\(|\)/g, '-')}')">`
                        }
                    </td>
                    <td>
                        <input type="number" class="quantity" value="${item.quantity}" oninput="updateNetCost(this, '${category
                    .toLowerCase()
                    .replace(/\s+|,|\(|\)/g, '-')}')">
                    </td>
                    <td>${item.unitType}</td>
                    <td class="unit-cost">$${parseFloat(item.netCost).toLocaleString()}</td>
                    <td>${item.remarks}</td>
                </tr>`;
            });

            newTableHtml += `
                <tr class="expandable" data-category="${category.toLowerCase().replace(/\s+|,|\(|\)/g, '-')}">
                    <td class="indent" style="font-weight: bold">Total</td>
                    <td></td><td></td><td></td>
                    <td id="${category.toLowerCase().replace(/\s+|,|\(|\)/g, '-')}-total-cost">$0</td>
                    <td></td>
                </tr>
            `;
        });

        // Close table HTML
        newTableHtml += `
            </tbody>
        </table>`;

        // Insert the new table into the body
        return newTableHtml;
    };

    const convertDataToZoningTable = (dataArray) => {
        // Object to store data by category
        const dataByRegulation = {};

        // Skip the first and last rows, as they are empty
        for (let i = 1; i < dataArray.length - 1; i++) {
            const row = dataArray[i];

            // If the row is the header row, skip it
            if (row[0] === 'Zoning Regulations') {
                continue;
            }

            const regulation = row[0];
            const element = row[1];
            const metric = row[2];
            const constraint = row[3];
            const standard = row[4];
            const status = row[5];

            // Add data to the corresponding category
            if (!dataByRegulation[regulation]) {
                dataByRegulation[regulation] = [];
            }

            dataByRegulation[regulation].push({
                element,
                metric,
                constraint,
                standard,
                status
            });
        }

        // Create the new HTML structure
        let newTableHtml = `
        <table class='csv-data'>
            <thead>
                <tr>
                    <th>REGULATION</th>
                    <th>ELEMENT</th>
                    <th>METRIC</th>
                    <th>CONSTRAINT</th>
                    <th>STANDARD</th>
                    <th>STATUS</th>
                </tr>
            </thead>
            <tbody>`;

        // Loop over each category to build the rows
        Object.keys(dataByRegulation).forEach((regulation) => {
            // Add category row
            newTableHtml += `
            <tr class="category-row total-row">
                <td>
                    <span class="expand-button" onclick="toggleVisibility('${regulation
                        .toLowerCase()
                        .replace(/\s+|,|\(|\)/g, '-')}', this)">▶ ${regulation}</span>
                </td>
                <td colspan="5"></td>
            </tr>`;

            // Add expandable rows for each item in the category
            dataByRegulation[regulation].forEach((item) => {
                newTableHtml += `
                <tr class="expandable" data-category="${regulation.toLowerCase().replace(/\s+|,|\(|\)/g, '-')}">
                    <td></td>
                    <td class="indent">${item.element}</td>
                    <td>${item.metric}</td>
                    <td>${item.constraint}</td>
                    <td>${item.standard}</td>
                    <td>${item.status}</td>
                </tr>`;
            });
        });

        // Close table HTML
        newTableHtml += `
            </tbody>
        </table>`;

        // Insert the new table into the body
        return newTableHtml;
    };

    const renderArchieResponse = (responseData, resolve, isRegulation) => {
        if (responseData.csv_representation) {
            Papa.parse(responseData.response, {
                header: false,
                complete: function (results) {
                    // Get the parsed data rows
                    const rows = results.data;
                    console.log('Rows ::', rows);

                    // Create a table element
                    const outerDiv = document.createElement('div');
                    outerDiv.classList.add('csv-data-outer');

                    const title = document.createElement('h2');
                    title.innerText = responseData.sheetTitle ? responseData.sheetTitle : 'Tabular calculation';
                    const description = document.createElement('h4');
                    description.innerText = responseData.sheetDescription
                        ? responseData.sheetDescription
                        : 'Descriptive breakdown of calculation';

                    outerDiv.append(title);

                    let table;
                    if ((rows[0].length > 1 && rows[0][0] === 'Cost Category') || (rows[1].length > 1 && rows[1][0] === 'Cost Category')) {
                        title.innerText = 'Bill of Quantities (BOQ)';
                        title.style.marginLeft = '20px';

                        function convertDataToGroupedFormat(data) {
                            const groupedData = [];
                            let totalCost = 0;

                            // Calculate the total cost first
                            for (let i = 2; i < data.length - 1; i++) {
                                const [category, , , , , netCost] = data[i];
                                const parsedNetCost = parseFloat(netCost);
                                if (!isNaN(parsedNetCost)) {
                                    totalCost += parsedNetCost;
                                }
                            }

                            // Now group data by category
                            for (let i = 2; i < data.length - 1; i++) {
                                const [category, element, rate, quantity, unitType, netCost, remarks] = data[i];

                                // Check if the category already exists in groupedData
                                let categoryGroup = groupedData.find((group) => group.category === category);

                                if (!categoryGroup) {
                                    // If the category doesn't exist, create a new category group
                                    categoryGroup = {
                                        category: category,
                                        totalCost: 0,
                                        elements: []
                                    };
                                    groupedData.push(categoryGroup);
                                }

                                const netCostValue = rate.endsWith('%')
                                    ? (parseFloat(rate) * (parseFloat(quantity) || 1)) / 100
                                    : parseFloat(netCost);

                                // Add the element to the category group
                                categoryGroup.elements.push({
                                    element: element,
                                    rate: rate,
                                    quantity: quantity,
                                    unitType: unitType,
                                    netCost: netCostValue,
                                    remarks: remarks
                                });

                                // Add to the category's total cost
                                categoryGroup.totalCost += parseFloat(netCost);
                            }

                            groupedData.forEach((group) => {
                                group.percentage = ((group.totalCost / totalCost) * 100).toFixed(2);
                            });

                            return { groupedData, totalCost };
                        }

                        function generateBOQCardsHTML(groupedData, totalCost) {
                            let wrapperContent = `<div class="boq-wrapper">`;

                            // Dropdown for categories (optional)
                            wrapperContent += `<div class="dropdown">
                                <label for="categoryDropdownCost">Items</label>
                                <select id="categoryDropdownCost" class="dropdown-select" onchange="(function () {
                                    const selectedCategory = document.querySelector('twinworld-app').shadowRoot.querySelector('#categoryDropdownCost').value;
                                    console.log('Selected category ::', selectedCategory);
                                    if (selectedCategory === 'all') {
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-container').style.display = 'grid';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-details').style.display = 'none';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.minWidth = '304px';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.width = '261px';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.transform = 'translateX(15px)';
                                    } else {
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-container').style.display = 'none';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-details').style.display = 'block';

                                        const allRows = document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.expandable[data-category]');
                                        allRows.forEach((row) => {
                                            row.classList.remove('show');
                                        });

                                        const rows = document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.expandable[data-category=\'+selectedCategory+\']');
                                        rows.forEach((row) => {
                                            row.classList.add('show');
                                        });

                                        updateTotal(selectedCategory);
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.minWidth = '450px';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.width = '429px';
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.transform = 'translateX(2px)';
                                    }
                                })()">`;

                            wrapperContent += `<option value="all">All</option>`;

                            groupedData.forEach((group) => {
                                wrapperContent += `<option value="${group.category.toLowerCase().replace(/\s+|,|\(|\)/g, '-')}">${
                                    group.category
                                }</option>`;
                            });

                            wrapperContent += `</select></div>`;

                            // Main BOQ cards
                            let htmlContent = `<div class="boq-container">`;

                            groupedData.forEach((group) => {
                                htmlContent += `
                                    <div class="boq-card" data-category-card="${group.category
                                        .toLowerCase()
                                        .replace(/ & /g, '-')
                                        .replace(/\s+|,|\(|\)/g, '-')}"
                                        onclick="(function (selectedCard) {
                                            const selectedCategory = selectedCard.getAttribute('data-category-card');
                                            console.log('Selected category ::', selectedCategory);
                                            if (selectedCategory === 'all') {
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-container').style.display = 'grid';
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-details').style.display = 'none';
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.minWidth = '304px';
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.width = '261px';
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.transform = 'translateX(15px)';
                                            } else {
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-container').style.display = 'none';
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-details').style.display = 'block';

                                                const allRows = document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.expandable[data-category]');
                                                allRows.forEach((row) => {
                                                    row.classList.remove('show');
                                                });

                                                const rows = document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.expandable[data-category=\'+selectedCategory+\']');
                                                rows.forEach((row) => {
                                                    row.classList.add('show');
                                                });

                                                updateTotal(selectedCategory);
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.minWidth = '450px';
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.width = '429px';
                                                document.querySelector('twinworld-app').shadowRoot.querySelector('.boq-wrapper .dropdown').style.transform = 'translateX(2px)';
                                            }
                                        })(this)">
                                        <h2>${group.category}</h2>
                                        <p class="percentage">${group.percentage}%</p>
                                        <p><span class="amount-label">Amount</span> <span class="amount-value ${group.category
                                            .toLowerCase()
                                            .replace(/\s+|,|\(|\)/g, '-')}-total-amount">${group.totalCost.toLocaleString()}</span></p>
                                    </div>`;
                            });

                            // Total card
                            htmlContent += `
                                <div class="boq-total-card">
                                    <h3 class="amount-total-label">Total</h3>
                                    <p><span class="amount-total-value">${totalCost.toLocaleString()}</span></p>
                                </div>
                            `;

                            htmlContent += `</div>`; // Close the container

                            wrapperContent += htmlContent;
                            wrapperContent += `</div>`; // Close the wrapper

                            return wrapperContent;
                        }

                        const originalData = rows;
                        const { groupedData, totalCost } = convertDataToGroupedFormat(originalData);

                        // Generate and insert HTML
                        const boqHTML = generateBOQCardsHTML(groupedData, totalCost);
                        const dataTableHTML = convertDataToCostTable(rows);
                        let totalWrapperContent = `<div class="boq-datatable-wrapper">`;
                        totalWrapperContent += boqHTML;
                        totalWrapperContent += dataTableHTML;
                        totalWrapperContent += `</div>`;

                        const tableElement = document.createElement('div');
                        tableElement.classList.add('csv-table-div');
                        tableElement.innerHTML = totalWrapperContent;
                        tableElement.style.maxHeight = '80vh';
                        outerDiv.append(tableElement);

                        /*
                        outerDiv.append(description);
                        table = convertDataToCostTable(rows);
                        const tableElement = document.createElement('div');
                        tableElement.classList.add('csv-table-div');
                        tableElement.innerHTML = table;
                        outerDiv.append(tableElement);
                        */
                    } else if (isRegulation) {
                        title.innerText = 'Regulation Compliance';
                        title.style.marginLeft = '20px';
                        function convertDataToGroupedFormat(data) {
                            const groupedData = [];

                            // Iterate over each row (skipping the header row)
                            for (let i = 1; i < data.length; i++) {
                                const [category, element, constraint, metric, standard, status] = data[i];

                                // Check if category already exists in groupedData
                                let categoryGroup = groupedData.find((group) => group.category === category);

                                if (!categoryGroup) {
                                    // If the category doesn't exist, create a new category group
                                    categoryGroup = {
                                        category: category,
                                        elements: []
                                    };
                                    groupedData.push(categoryGroup);
                                }

                                // Add the element to the category group
                                categoryGroup.elements.push({
                                    element: element,
                                    metric: metric,
                                    constraint: constraint,
                                    standard: standard,
                                    status: status
                                });
                            }

                            return groupedData;
                        }

                        // Example usage with your data
                        const originalData = rows;

                        // Convert data
                        window.groupedData = convertDataToGroupedFormat(originalData);
                        console.log(groupedData);

                        function generateCategoryDetails(category) {
                            let detailsHTML = '';
                            category.elements.forEach((element) => {
                                detailsHTML += `
                                    <div class="element-details" style="border-bottom: 2px solid #D9D9D9; padding: 10px 0;">
                                        <h3>${element.element}</h3>
                                        <p><strong>Description:</strong> ${element.constraint}</p>
                                        <p>${element.metric}</p>
                                        <label>Applicability</label>
                                        <select style="display: block; margin-bottom: 10px;">
                                            <option value="Applicable">Applicable</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                        </select>
                                        <p><strong>Compliance:</strong> <span style="color: ${
                                            element.status === 'Compliant' ? '#0BAA06' : 'red'
                                        }; display: block">${element.status === 'Compliant' ? 'Pass' : 'Fail'}</span></p>
                                    </div>
                                `;
                            });
                            return detailsHTML;
                        }

                        function generateDropdownFromGroupedData(groupedData) {
                            // Create a container div for the dropdown
                            let dropdownHTML = `<div class="dropdown">
                                <label for="categoryDropdown">Category</label>
                                <select id="categoryDropdown" class="dropdown-select" onchange="(function () {
                                    const selectedCategory = document.querySelector('twinworld-app').shadowRoot.querySelector('#categoryDropdown').value;
                                    console.log('Selected category ::', selectedCategory);
                                    if (selectedCategory === 'all') {
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.compliance-container').style.display = 'grid';
                                        document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.category-details').forEach(el => el.style.display = 'none');
                                    } else {
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.compliance-container').style.display = 'none';
                                        document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.category-details').forEach(el => el.style.display = 'none');
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('#category-'+selectedCategory).style.display = 'block';
                                    }
                            })()">`;

                            // Add the default "All" option
                            dropdownHTML += `<option value="all">All</option>`;

                            // Loop through the groupedData array and create an option for each unique category
                            groupedData.forEach((group) => {
                                const category = group.category;
                                dropdownHTML += `<option value="${category
                                    .toLowerCase()
                                    .replace(/ & /g, '-')
                                    .replace(/\s+|,|\(|\)/g, '-')}">${category}</option>`;
                            });

                            // Close the select and div tags
                            dropdownHTML += `</select></div>`;

                            // Append the generated HTML to the body or any desired container
                            return dropdownHTML;
                        }

                        function generateHTML(groupedData) {
                            // Initialize an empty string for HTML content

                            let wrapperContent = `<div class="compliance-wrapper">`;
                            const complianceDropDown = generateDropdownFromGroupedData(groupedData);
                            wrapperContent += complianceDropDown;

                            let htmlContent = `<div class="compliance-container">`;

                            // Iterate over each category group
                            groupedData.forEach((group) => {
                                htmlContent += `
                                <div class="compliance-card" data-category-card="${group.category
                                    .toLowerCase()
                                    .replace(/ & /g, '-')
                                    .replace(/\s+|,|\(|\)/g, '-')}"
                                    onClick="(function (selectedCard) {
                                        const selectedCategory = selectedCard.getAttribute('data-category-card');
                                        console.log('Selected category ::', selectedCategory);
                                        if (selectedCategory === 'all') {
                                            document.querySelector('twinworld-app').shadowRoot.querySelector('.compliance-container').style.display = 'grid';
                                            document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.category-details').forEach(el => el.style.display = 'none');
                                        } else {
                                            document.querySelector('twinworld-app').shadowRoot.querySelector('.compliance-container').style.display = 'none';
                                            document.querySelector('twinworld-app').shadowRoot.querySelectorAll('.category-details').forEach(el => el.style.display = 'none');
                                            document.querySelector('twinworld-app').shadowRoot.querySelector('#category-'+selectedCategory).style.display = 'block';
                                        }
                                })(this)">
                                    <h2>${group.category}</h2>
                                    <p><strong>Applicability:</strong> <span class="applicability">Applicable</span></p>
                                    <p><strong>Compliance:</strong> <span class="compliant">Pass</span></p>
                                </div>`;
                            });

                            let allCompliant = true;

                            // Loop through each category and its elements
                            for (let group of groupedData) {
                                for (let element of group.elements) {
                                    if (element.status !== 'Compliant') {
                                        allCompliant = false;
                                        break; // If any element is not compliant, no need to check further
                                    }
                                }
                                if (!allCompliant) break;
                            }

                            // Determine the final compliance status
                            const statusText = allCompliant ? 'Pass' : 'Fail';
                            const statusColor = allCompliant ? '#0BAA06' : 'red';

                            // Create the final compliance card HTML
                            const complianceCardHTML = `
                                <div class="final-compliance-card" style="padding: 0; margin: 10px 0; text-align: center;">
                                    <p style="color: ${statusColor}; font-size: 24px; font-weight: bold; margin: 0">${statusText}</p>
                                </div>
                            `;

                            htmlContent += complianceCardHTML;

                            // Close the main container div
                            htmlContent += `</div>`;

                            // Generate hidden category-specific details
                            groupedData.forEach((group) => {
                                // Initially hide category details, visible when selected
                                htmlContent += `<div class="category-details" id="category-${group.category
                                    .toLowerCase()
                                    .replaceAll(' ', '-')}" style="display: none;">`;
                                htmlContent += generateCategoryDetails(group);
                                htmlContent += `</div>`;
                            });

                            wrapperContent += htmlContent;

                            wrapperContent += `</div>`;

                            // Return the complete HTML string
                            return wrapperContent;
                        }

                        // Generate HTML
                        const html = generateHTML(groupedData);
                        console.log(html);

                        const tableElement = document.createElement('div');
                        tableElement.classList.add('csv-table-div');
                        tableElement.innerHTML = html;
                        tableElement.style.maxHeight = '80vh';
                        outerDiv.append(tableElement);
                    } else {
                        outerDiv.append(description);
                        table = document.createElement('table');
                        table.classList.add('csv-data');
                        table.border = 2;

                        // Process each row
                        rows.forEach((row, rowIndex) => {
                            const tr = document.createElement('tr');

                            // Process each cell in the row
                            row.forEach((cell) => {
                                const cellElement = rowIndex === 0 ? 'th' : 'td';
                                const td = document.createElement(cellElement);
                                td.textContent = cell;
                                tr.appendChild(td);
                            });

                            // Append the row to the table
                            table.appendChild(tr);
                        });
                        const tableElement = document.createElement('div');
                        tableElement.classList.add('csv-table-div');
                        tableElement.append(table);
                        outerDiv.append(tableElement);
                    }

                    window.sheetList = outerDiv;
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').innerHTML = '';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').append(window.sheetList);
                    setTimeout(() => {
                        const categories = document.querySelector('twinworld-app').shadowRoot.querySelectorAll('[data-category]');
                        categories.forEach((category) => updateTotal(category.getAttribute('data-category')));
                    }, 2000);
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'block';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';
                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
                }
            });
            if (resolve) {
                if (responseData.descriptiveResponse) {
                    resolve(responseData.descriptiveResponse);
                } else {
                    resolve('Response is rendered in tabular format');
                }
            }
        } else if (responseData.response.indexOf('DOCTYPE html') > -1) {
            console.log('Response starts with html');
            const outerDiv = document.createElement('div');
            outerDiv.classList.add('csv-data-outer');
            const tableElement = document.createElement('div');
            tableElement.classList.add('csv-table-div');
            tableElement.innerHTML = responseData.response;
            outerDiv.append(tableElement);
            window.sheetList = outerDiv;
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').innerHTML = '';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').append(window.sheetList);
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'block';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
            if (resolve) {
                resolve('Please fill in the generated form');
            }
        } else {
            if (resolve) {
                resolve(responseData.response);
            }
            window.sheetList = null;
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'none';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility = 'hidden';
            document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0;
        }
    };

    window.renderArchieResponse = renderArchieResponse;

    const handleSubmit = (text) => {
        console.log('Chat submitted in twinworld-app : ', text);
        return new Promise((resolve) => {
            const searchSceneId =
                window.location.pathname.indexOf('twinworld') >= 0 ? window.location.pathname.split('/').splice(-1)[0] : undefined;
            if (!searchSceneId && !document.querySelector('.createAnalyzeMenu')) {
                const createAnalyzeMenu = document.createElement('div');
                createAnalyzeMenu.classList.add('createAnalyzeMenu');
                document.querySelector('.MuiStack-root').parentNode.append(createAnalyzeMenu);
                document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-geocoder-input').style.transform =
                    'translateY(30px)';
                document.querySelector('twinworld-app').shadowRoot.querySelector('[aria-label="search"]').style.transform =
                    'translateX(30px) translateY(-10px)';
                // document.querySelector('twinworld-app').shadowRoot.querySelector('.plusIcon').style.transform = 'translateY(30px)';
                document.querySelector('twinworld-app').shadowRoot.querySelector('[aria-label="mailbox folders"]').style.background =
                    'black';
                document.querySelector('twinworld-app').shadowRoot.querySelector('.searchBtn button').style.transform =
                    'translateX(300px) translateY(-10px)';
                document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper').style.width = '64px';
            }
            const cookieData = JSON.parse(twinupCookie.replace(/\\054/g, ',').replace(/\\/g, '').replace(/^\"/, '').replace(/\"$/, ''));
            const accessToken = cookieData.access_token;
            document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';
            document.querySelector('#promptInput').disabled = true;
            axios
                .post(
                    `https://dev.thetwinmaster.com/twinworld/usdaJsonAgent`,
                    { userPrompt: text, name: '', interpretOnly: true },
                    {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                .then((response) => {
                    const openAIResponse = response.data;
                    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                    // setMessage('Scene created', resolve);
                    console.log('Response from openai ::', openAIResponse);

                    if (openAIResponse.intent === 'navigate_to_given_location') {
                        const locationName = openAIResponse.name;
                        const latitude = openAIResponse.latitude;
                        const longitude = openAIResponse.longitude;
                        axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${locationName}`).then((response) => {
                            const mapLocationData = response.data[0];
                            const boundingbox = mapLocationData.boundingbox;
                            window.destBoundingBox = {
                                south: (boundingbox[0] * Cesium.Math.PI) / 180,
                                north: (boundingbox[1] * Cesium.Math.PI) / 180,
                                west: (boundingbox[2] * Cesium.Math.PI) / 180,
                                east: (boundingbox[3] * Cesium.Math.PI) / 180
                            };
                            console.log('Dest bounding box :: ', window.destBoundingBox);
                            setTimeout(() => {
                                viewer.camera.flyTo({
                                    destination: Cesium.Cartesian3.fromDegrees(mapLocationData.lon, mapLocationData.lat, 1005.0)
                                });
                            }, 2000);
                            resolve(`Going to ${locationName} (${mapLocationData.lat}, ${mapLocationData.lon})`);
                            document.querySelector('twinworld-app').shadowRoot.querySelector('.simplebar-wrapper').parentNode.style.height =
                                '100%';
                            document
                                .querySelector('twinworld-app')
                                .shadowRoot.querySelector('.simplebar-wrapper').parentNode.parentNode.parentNode.style.width = '64px';
                            setTimeout(() => {
                                document.querySelector('#promptInput').disabled = false;
                            }, 1000);
                        });
                    } else if (openAIResponse.intent === 'find_neighborhood_buildings_within_given_bounding_area') {
                        document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';
                        document.querySelector('#promptInput').disabled = true;
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'none';
                        fetch('https://dev.thetwinmaster.com/twinworld/getArchieSupport', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            credentials: 'include',
                            body: JSON.stringify({
                                userPrompt: `Give me only the search item out of (hospital, apartments, commercial) and the coverage area and unit of the coverage area out of (kilometer, meter, mile, feet) of search which is requested in the following text. Return only json object with keys search_option and requested_coverage and unit_of_requested_coverage with values in lower case : ${text}`
                            })
                        })
                            .then((archieRes) => archieRes.json())
                            .then((archieResJsonTotal) => {
                                let coverageMeters = 0;
                                const archieResJson = archieResJsonTotal.response ? archieResJsonTotal.response : archieResJsonTotal;
                                console.log('Response for getArchieSupport in response :: ', archieResJson);
                                const unit = archieResJson.unit_of_requested_coverage;
                                const value = parseFloat(archieResJson.requested_coverage);
                                if (unit.toLowerCase().indexOf('kilometer') > -1) {
                                    coverageMeters = value * 1000;
                                } else if (unit.toLowerCase().indexOf('meter') > -1) {
                                    coverageMeters = value;
                                } else if (unit.toLowerCase().indexOf('mile') > -1) {
                                    coverageMeters = value * 1609.34;
                                } else if (unit.toLowerCase().indexOf('feet') > -1 || unit.toLowerCase().indexOf('foot') > -1) {
                                    coverageMeters = value * 0.3048;
                                }
                                window.scanState = 'scanning';
                                window.entityList = [];
                                // document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display = 'block';
                                // document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility =
                                //     'visible';
                                // document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
                                if (window.poiFlagPoints) {
                                    window.poiFlagPoints.forEach((poiFlagPoint) => {
                                        viewer.entities.remove(poiFlagPoint);
                                    });
                                }
                                window.poiFlagPoints = [];
                                window.locationIntelSearchData = {
                                    latitudeDegree: (window.destCenter.latitude * 180) / Cesium.Math.PI,
                                    longitudeDegree: (window.destCenter.longitude * 180) / Cesium.Math.PI,
                                    buildingType: archieResJson.search_option,
                                    requestedCoverageMeters: coverageMeters,
                                    sceneId: window.newSceneId
                                };
                                const locationDataForOutput = {
                                    latitudeDegree: (window.destCenter.latitude * 180) / Cesium.Math.PI,
                                    longitudeDegree: (window.destCenter.longitude * 180) / Cesium.Math.PI,
                                    buildingType: archieResJson.search_option,
                                    requestedCoverageMeters: coverageMeters,
                                    sceneId: window.newSceneId
                                };
                                fetch('https://dev.thetwinmaster.com/twinworld/getLocationIntel', {
                                    method: 'POST',
                                    credentials: 'include',
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    credentials: 'include',
                                    body: JSON.stringify(window.locationIntelSearchData)
                                })
                                    .then((res) => res.json())
                                    .then((resJson) => {
                                        window.allPickedAssets = resJson;
                                        window.changeOriginOnClickGlobal = false;
                                        // window.startRobotScanner(200, true, false);
                                        window.scanState = 'complete';
                                        const uniqueAssets = Array.from(
                                            new Set(
                                                resJson
                                                    .filter((pickedAsset) => {
                                                        return pickedAsset.name && !pickedAsset.name.endsWith('undefined )');
                                                    })
                                                    .filter((pickedAsset) => pickedAsset.name !== window.currentAssetName)
                                                    .map(
                                                        (pickedAsset) =>
                                                            `${pickedAsset.name.split('( Status')[0]} (Lat: ${pickedAsset.latitude.toFixed(
                                                                3
                                                            )}, Lon: ${pickedAsset.longitude.toFixed(3)})`
                                                    )
                                            )
                                        );
                                        uniqueAssets.map((uniqueAsset, indx) => ['(', indx + 1, ') ', uniqueAsset].join(''));
                                        window.entityList = Array.from(
                                            new Set(
                                                resJson
                                                    .filter((pickedAsset) => {
                                                        return pickedAsset.name && !pickedAsset.name.endsWith('undefined )');
                                                    })
                                                    .filter((pickedAsset) => pickedAsset.name !== window.currentAssetName)
                                                    .map((pickedAsset) => (
                                                        <span>
                                                            {pickedAsset.name.split('( Status')[0]}
                                                            <br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {'    ( Status' + pickedAsset.name.split('( Status')[1]}
                                                            <br />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lat:{' '}
                                                            {pickedAsset.latitude.toFixed(3)}&nbsp; Lon: {pickedAsset.longitude.toFixed(3)}
                                                        </span>
                                                    ))
                                            )
                                        );
                                        resJson
                                            .filter((data) => {
                                                return !data.name.endsWith('undefined )');
                                            })
                                            .forEach((formattedPoi, index) => {
                                                var flag = new Cesium.PinBuilder();
                                                var flagProperties = new Cesium.PropertyBag();
                                                flagProperties.addProperty('MyProperty_A', 0);
                                                flagProperties.addProperty('MyProperty_B', 1);
                                                // Create an Entity
                                                var flagPoint = viewer.entities.add({
                                                    name: 'pole',
                                                    position: Cesium.Cartesian3.fromDegrees(
                                                        formattedPoi.longitude,
                                                        formattedPoi.latitude,
                                                        window.destinationSeaLevelHeight
                                                    ),
                                                    billboard: {
                                                        image: flag.fromUrl(
                                                            formattedPoi.pinIcon,
                                                            new Cesium.Color.fromCssColorString('#1e90ff99'),
                                                            50
                                                        ),
                                                        // image: flag.fromUrl(formattedPoi.pinIcon, Cesium.Color.DODGERBLUE, 50),
                                                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                                                    },
                                                    properties: flagProperties
                                                });
                                                window.poiFlagPoints.push(flagPoint);
                                                var flagNumPoint = viewer.entities.add({
                                                    name: 'pole',
                                                    position: Cesium.Cartesian3.fromDegrees(
                                                        formattedPoi.longitude,
                                                        formattedPoi.latitude,
                                                        window.destinationSeaLevelHeight
                                                    ),
                                                    billboard: {
                                                        image: flag.fromText(index + 1, Cesium.Color.DODGERBLUE, 25).toDataURL(),
                                                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
                                                    },
                                                    properties: flagProperties
                                                });
                                                window.poiFlagPoints.push(flagNumPoint);
                                            });
                                        document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
                                        setTimeout(() => {
                                            document.querySelector('twinworld-app').shadowRoot.getElementById('contextData').click();
                                            /*document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.display = 'block';
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';*/
                                        }, 20);
                                        viewer.camera.flyTo({
                                            destination: viewer.scene.camera.position,
                                            orientation: {
                                                heading: viewer.camera.heading - 0.001,
                                                pitch: viewer.camera.pitch,
                                                roll: 0.0
                                            }
                                        });
                                        resolve(
                                            `Rendering following ${
                                                locationDataForOutput.buildingType
                                            } in ${locationDataForOutput.requestedCoverageMeters.toFixed(2)} meters radius:
                                            ${uniqueAssets.length > 0 ? '(1) ' : ''} ${uniqueAssets.reduce(
                                                (assetConcatStr, currAsset, currIndx) => {
                                                    return assetConcatStr + '\n' + '(' + (currIndx + 1) + ') ' + currAsset;
                                                }
                                            )}`
                                        );
                                        document
                                            .querySelectorAll('.chatAgent > div > div > div > div')
                                            .forEach((chatText) => (chatText.style.whiteSpace = 'pre-line'));
                                        setTimeout(() => {
                                            document.querySelector('#promptInput').disabled = false;
                                        }, 1000);
                                    });
                                document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                            });
                    } else if (openAIResponse.intent === 'tell_me_details_of_building_or_scene') {
                        const followUpData =
                            window.activeOption.startsWith('cost') || window.activeOption.startsWith('zoning')
                                ? 'in the following data: ' + window.contextData
                                : '';
                        if (window.worldSceneId) {
                            fetch('https://dev.thetwinmaster.com/twinworld/getArchieSupport/', {
                                headers: {
                                    accept: 'application/json, text/plain, */*',
                                    'content-type': 'application/json'
                                },
                                body: JSON.stringify({
                                    userPrompt: {
                                        promptText: `${text} ${followUpData}`,
                                        sceneId: window.worldSceneId
                                    }
                                }),
                                method: 'POST',
                                mode: 'cors',
                                credentials: 'include'
                            })
                                .then((res) => res.json())
                                .then((resJson) => {
                                    renderArchieResponse(resJson, resolve);
                                    setTimeout(() => {
                                        document.querySelector('#promptInput').disabled = false;
                                    }, 1000);
                                    if (window.activeOption.startsWith('cost') || window.activeOption.startsWith('zoning')) {
                                        setTimeout(() => {
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.display = 'block';
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.visibility = 'visible';
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
                                        }, 2000);
                                    }
                                });
                        } else {
                            fetch('https://dev.thetwinmaster.com/twinworld/getArchieSupport/', {
                                headers: {
                                    accept: 'application/json, text/plain, */*',
                                    'content-type': 'application/json'
                                },
                                body: `{"userPrompt":"{\\"promptText\\":\\"${text}\\"}"}`,
                                method: 'POST',
                                mode: 'cors',
                                credentials: 'include'
                            })
                                .then((res) => res.json())
                                .then((resJson) => {
                                    renderArchieResponse(resJson, resolve);
                                    setTimeout(() => {
                                        document.querySelector('#promptInput').disabled = false;
                                    }, 1000);
                                });
                        }
                    } else if (openAIResponse.intent === 'refers_to_past_tense_or_something_that_is_higher_or_above') {
                        const followUpData =
                            window.activeOption.startsWith('cost') || window.activeOption.startsWith('zoning')
                                ? 'in the following data: ' + window.contextData
                                : '';
                        fetch('https://dev.thetwinmaster.com/twinworld/getArchieSupport/', {
                            headers: {
                                accept: 'application/json, text/plain, */*',
                                'content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                userPrompt: {
                                    promptText: `${text} ${followUpData}`,
                                    chainRequests: true
                                }
                            }),
                            method: 'POST',
                            mode: 'cors',
                            credentials: 'include'
                        })
                            .then((res) => res.json())
                            .then((resJson) => {
                                const responseData = resJson.response;
                                resolve(responseData);
                                setTimeout(() => {
                                    document.querySelector('#promptInput').disabled = false;
                                }, 1000);
                                setTimeout(() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display =
                                        'block';
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility =
                                        'visible';
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
                                }, 2000);
                            });
                    } else if (openAIResponse.intent === 'refers_to_generic_calculation_or_information') {
                        const followUpData =
                            window.activeOption.startsWith('cost') || window.activeOption.startsWith('zoning')
                                ? 'in the following data: ' + window.contextData
                                : '';
                        fetch('https://dev.thetwinmaster.com/twinworld/getArchieSupport/', {
                            headers: {
                                accept: 'application/json, text/plain, */*',
                                'content-type': 'application/json'
                            },
                            body:
                                openAIResponse.sub_intent === 'has_this_or_similar_function'
                                    ? JSON.stringify({
                                          userPrompt: {
                                              promptText: `${text} ${followUpData}`,
                                              sceneId: window.worldSceneId
                                          }
                                      })
                                    : JSON.stringify({
                                          userPrompt: {
                                              promptText: `${text} ${followUpData}`
                                          }
                                      }),
                            method: 'POST',
                            mode: 'cors',
                            credentials: 'include'
                        })
                            .then((res) => res.json())
                            .then((resJson) => {
                                renderArchieResponse(resJson, resolve);
                                setTimeout(() => {
                                    document.querySelector('#promptInput').disabled = false;
                                }, 1000);
                                setTimeout(() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.display =
                                        'block';
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.visibility =
                                        'visible';
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
                                }, 2000);
                            });
                    }
                    // window.sceneData = sceneData;
                    // document.querySelector('twinworld-app').shadowRoot.querySelector('#screenshot').addEventListener('click', takeScreenShot);
                });
        });
        setSearchTerm(text);
    };

    React.useEffect(() => {
        // const twinupCookie = Cookies.get('dev-twinup');
        const cookieData = JSON.parse(twinupCookie.replace(/\\054/g, ',').replace(/\\/g, '').replace(/^\"/, '').replace(/\"$/, ''));
        // document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';

        const sceneUrl = new URLSearchParams(window.location.search);
        const searchSceneId =
            window.location.pathname.indexOf('twinworld') >= 0 ? window.location.pathname.split('/').splice(-1)[0] : undefined;
        if (searchSceneId) {
            if (document.querySelector('#folderWrapper')) {
                document.querySelector('#folderWrapper').style.display = 'none';
            }
            document.querySelector('twinworld-app').shadowRoot.querySelector('#cesiumContainer div').remove();
            if (document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar')) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar').remove();
            }
            if (document.querySelector('twinworld-app').shadowRoot.querySelector('#root header')) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#root header').remove();
            }
            if (document.querySelector('twinworld-app').shadowRoot.querySelector('#twoDimThreeDimSwitch')) {
                document.querySelector('twinworld-app').shadowRoot.querySelector('#twoDimThreeDimSwitch').remove();
            }
            if (
                document.querySelector('twinworld-app').shadowRoot.querySelector('.simplebar-wrapper') &&
                document.querySelector('twinworld-app').shadowRoot.querySelector('.simplebar-wrapper').parentNode.parentNode.parentNode
                    .parentNode
            ) {
                document
                    .querySelector('twinworld-app')
                    .shadowRoot.querySelector('.simplebar-wrapper').parentNode.parentNode.parentNode.parentNode.style.display = 'none';
            }
            if (document.querySelector('.createAnalyzeMenu')) {
                document.querySelector('.createAnalyzeMenu').remove();
            }
        }
        if (!document.querySelector('#papaparse')) {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://cdnjs.cloudflare.com/ajax/libs/PapaParse/5.3.0/papaparse.min.js';
            script.id = 'papaparse';
            document.head.appendChild(script);
        }
        setTimeout(() => {
            setTimeout(() => {
                viewer.scene.mode = Cesium.SceneMode.SCENE2D;
                fetch(
                    searchSceneId
                        ? `https://dev.thetwinmaster.com/twinworld/getSceneById/${searchSceneId}`
                        : 'https://dev.thetwinmaster.com/twinworld/listAllScenesForUser',
                    {
                        credentials: 'include'
                    }
                )
                    .then((res) => res.json())
                    .then((resJson) => {
                        const allScenes = resJson;
                        setSceneList(allScenes);
                        setSceneItemsDom(sceneItems(sceneType, selectedFolder, allScenes));
                        window.twinWorldSceneList = allScenes;
                        const buildingGlbMap = {};
                        if (searchSceneId) {
                            const sceneArr = allScenes.filter((scene) => scene.scene_id === searchSceneId);
                            const scene = sceneArr.length > 0 ? sceneArr[0] : null;
                            if (scene) {
                                if (scene.BuildingScene) {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('main').innerHTML =
                                        '<p class="MuiTypography-root MuiTypography-body1 css-s9wadb-MuiTypography-root">TwinUp Building Application</p>';

                                    // const buildingSceneParts = scene.BuildingScene.split(',');
                                    // const buildings3Location = buildingSceneParts.length > 1 ? buildingSceneParts[1] : null;
                                    // if (buildings3Location) {
                                    //     getBuildingUriGlb(scene.buildingid, buildings3Location, null).then((buildingGlbUri) => {
                                    //         buildingGlbMap[scene.buildingid] = buildingGlbUri.glb;
                                    //         scene.local_glb_uri = buildingGlbUri.glb;
                                    //         setTimeout(() => {
                                    //             loadBuildingScene(scene);
                                    //         }, 100);
                                    //     });
                                    // }

                                    window.location.href = `twinup://ImportApp?access_token=${accessUid}wsid=${scene.scene_id}`;
                                    const cookieData = JSON.parse(
                                        twinupCookie.replace(/\\054/g, ',').replace(/\\/g, '').replace(/^\"/, '').replace(/\"$/, '')
                                    );
                                } else {
                                    buildingDetails(
                                        cookieData.access_token,
                                        scene.buildingid,
                                        scene.buildingS3Location,
                                        scene.structuralSystemS3Location
                                    ).then((buildingData) => {
                                        const buildingS3Location = buildingData.data.Building[0].s3location;
                                        const substring = ',';
                                        const index = buildingS3Location.indexOf(substring);
                                        if (buildingS3Location !== '') {
                                            const formattedBuildingS3Loc =
                                                index !== -1 ? buildingS3Location.substring(0, index) : buildingS3Location;
                                            const formattedThumbnailLoc =
                                                index !== -1 ? buildingS3Location.substring(index + 1, buildingS3Location.length) : '';
                                            const leanModelS3Location =
                                                buildingData.data.Building[0].StructuralSystem &&
                                                buildingData.data.Building[0].StructuralSystem.s3location
                                                    ? buildingData.data.Building[0].StructuralSystem.s3location
                                                    : null;
                                            // const finalBuildingS3Loc = leanModelS3Location ? leanModelS3Location : formattedBuildingS3Loc;
                                            getBuildingUriGlb(scene.buildingid, formattedBuildingS3Loc, formattedThumbnailLoc).then(
                                                (buildingGlbUri) => {
                                                    buildingGlbMap[scene.buildingid] = buildingGlbUri.glb;
                                                    scene.local_glb_uri = buildingGlbUri.glb;
                                                    scene.local_thumbnail_uri = buildingGlbUri.thumbnail;
                                                    viewer.clock.onTick.removeEventListener(window.applyGlobeSpin);
                                                    setTimeout(() => {
                                                        Cesium.createGooglePhotorealistic3DTileset(Cesium.GoogleMaps.defaultApiKey, {
                                                            imageryProvider: true,
                                                            baseLayerPicker: true,
                                                            requestRenderMode: true,
                                                            showCreditsOnScreen: true
                                                        }).then((tileset) => {
                                                            const googlePhotoRealisticTiles = tileset;
                                                            window.googlePhotoRealisticTileset =
                                                                window.viewer.scene.primitives.add(googlePhotoRealisticTiles);
                                                        });
                                                        console.log('Loading scene from community frame:: ', scene);
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('#robot-scanner').style.opacity = 0;
                                                        window.loadScene(scene);
                                                        viewer.scene.mode = Cesium.SceneMode.SCENE3D;
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('.compass').style.right = '100px';
                                                        document
                                                            .querySelector('twinworld-app')
                                                            .shadowRoot.querySelector('.navigation-controls').style.right = '130px';
                                                    }, 100);
                                                }
                                            );
                                        }
                                    });
                                }
                            }
                        } else {
                            document.querySelector('twinworld-app').shadowRoot.querySelector('.blackbg').style.display = 'block';
                            const allScenesData = allScenes.map((scene) =>
                                scene.stages && scene.stages.length >= 1 && scene.stages[0].layers[0].prims.length > 0
                                    ? Object.assign(
                                          { id: scene.id, name: scene.name, description: scene.description, assetName: scene.name },
                                          scene.stages[0].layers[0].prims[0].attributes.reduce((sceneMap, obj) => {
                                              sceneMap[obj.name] = obj.value;
                                              return sceneMap;
                                          }, {}),
                                          scene.stages.filter((stage) => stage.name === 'POI').length > 0
                                              ? {
                                                    pois: scene.stages
                                                        .filter((stage) => stage.name === 'POI')[0]
                                                        .layers[0].prims.map((prim) => {
                                                            return prim.attributes.reduce((sceneMap, obj) => {
                                                                sceneMap[obj.name] = obj.value;
                                                                return sceneMap;
                                                            }, {});
                                                        })
                                                }
                                              : {}
                                      )
                                    : {}
                            );
                            allScenesData.forEach((sceneData) => {
                                console.log('Updating scene :: ', sceneData);
                                const sceneEntity = viewer.entities.add({
                                    position: Cesium.Cartesian3.fromDegrees(sceneData.longitude, sceneData.latitude),
                                    billboard: {
                                        height: 50,
                                        width: 50,
                                        image: sceneData.sceneImage
                                            ? `/twinworld/downloadSceneImage?imageId=${sceneData.sceneImage
                                                  .split('/')
                                                  .splice(-2)
                                                  .join('/')}`
                                            : 'https://dtuas2sql0dg4.cloudfront.net/build/cesium/Widgets/Images/ImageryProviders/bingAerial.png',
                                        outlineColor: new Cesium.Color((243 * 1.0) / 255, (111 * 1.0) / 255, (75 * 1.0) / 255, 1),
                                        outlineWidth: 3,
                                        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                                        pixelOffset: new Cesium.Cartesian2(0, -15)
                                    },
                                    label: {
                                        text: sceneData.name,
                                        font: '0.5em Suisse Intl',
                                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                                        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
                                        verticalOrigin: Cesium.VerticalOrigin.BASELINE,
                                        fillColor: Cesium.Color.GHOSTWHITE,
                                        showBackground: true,
                                        backgroundColor: Cesium.Color.DARKSLATEGREY.withAlpha(0.8),
                                        backgroundPadding: new Cesium.Cartesian2(8, 16),
                                        pixelOffset: new Cesium.Cartesian2(36, -48),
                                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                                    }
                                });
                                sceneEntity.label.scale = 0;
                                const scenePointer = viewer.entities.add({
                                    position: Cesium.Cartesian3.fromDegrees(sceneData.longitude, sceneData.latitude),
                                    point: {
                                        color: Cesium.Color.CORNFLOWERBLUE,
                                        pixelSize: 0,
                                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                                        disableDepthTestDistance: Number.POSITIVE_INFINITY
                                    }
                                });
                                sceneEntity.refPoint = scenePointer.point;
                                let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
                                handler.setInputAction(function (movement) {
                                    const pickedObject = viewer.scene.pick(movement.endPosition);
                                    if (Cesium.defined(pickedObject) && pickedObject.id === sceneEntity) {
                                        if (!sceneEntity.billboard.scale || sceneEntity.billboard.scale < 1.5) {
                                            // sceneEntity.label.show = true;
                                            sceneEntity.billboard.scale = 1.5;
                                            sceneEntity.label.scale = 1.2;
                                            sceneEntity.billboard.outlineColor = Cesium.Color.FORESTGREEN;
                                            viewer.camera.zoomIn();
                                        }
                                        //viewer.camera.zoomOut();
                                    } else {
                                        if (sceneEntity.billboard.scale == 1.5) {
                                            // sceneEntity.label.show = false;
                                            sceneEntity.billboard.scale = 1.0;
                                            sceneEntity.label.scale = 0;
                                            sceneEntity.billboard.outlineColor = Cesium.Color.WHITE;
                                            viewer.camera.zoomIn();
                                        }
                                    }
                                }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

                                handler.setInputAction(function (clickEvent) {
                                    const pickedObject = viewer.scene.pick(clickEvent.position);
                                    if (Cesium.defined(pickedObject) && pickedObject.id === sceneEntity) {
                                        sceneEntity.billboard.scale = 0;
                                        sceneEntity.label.scale = 0;
                                        sceneEntity.refPoint.pixelSize = 0;
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper').parentNode.parentNode.style.display =
                                            'block';
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper').style.width = '64px';
                                        if (
                                            !searchSceneId &&
                                            !document.querySelector('.MuiStack-root').parentNode.querySelector('.createAnalyzeMenu')
                                        ) {
                                            const createAnalyzeMenu = document.createElement('div');
                                            createAnalyzeMenu.classList.add('createAnalyzeMenu');
                                            document.querySelector('.MuiStack-root').parentNode.append(createAnalyzeMenu);
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('.cesium-geocoder-input').style.transform = 'translateY(30px)';
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('[aria-label="search"]').style.transform =
                                                'translateX(30px) translateY(-10px)';
                                            // document.querySelector('twinworld-app').shadowRoot.querySelector('.plusIcon').style.transform =
                                            //     'translateY(30px)';
                                        }
                                        sceneData.latitude = parseFloat(sceneData.latitude);
                                        sceneData.longitude = parseFloat(sceneData.longitude);
                                        const boundingBox =
                                            typeof sceneData.boundingBox === 'string'
                                                ? JSON.parse(sceneData.boundingBox)
                                                : sceneData.boundingBox;
                                        sceneData.boundingBox = JSON.stringify({
                                            west: boundingBox[0],
                                            south: boundingBox[1],
                                            east: boundingBox[2],
                                            north: boundingBox[3]
                                        });
                                        Cesium.createGooglePhotorealistic3DTileset(Cesium.GoogleMaps.defaultApiKey, {
                                            imageryProvider: true,
                                            baseLayerPicker: true,
                                            requestRenderMode: true,
                                            showCreditsOnScreen: true
                                        }).then((tileset) => {
                                            const googlePhotoRealisticTiles = tileset;
                                            window.googlePhotoRealisticTileset =
                                                window.viewer.scene.primitives.add(googlePhotoRealisticTiles);
                                        });
                                        console.log('Loading scene :: ', sceneData);
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('#robot-scanner').style.opacity = 0;
                                        window.loadScene(sceneData);
                                        viewer.scene.mode = Cesium.SceneMode.SCENE3D;
                                        document.querySelector('twinworld-app').shadowRoot.querySelector('.compass').style.right = '100px';
                                        document
                                            .querySelector('twinworld-app')
                                            .shadowRoot.querySelector('.navigation-controls').style.right = '130px';
                                        setTimeout(() => {
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('[aria-label="mailbox folders"]').style.background = 'black';
                                            document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('.cesium-viewer-geocoderContainer').style.display = 'none';
                                            /*document
                                                .querySelector('twinworld-app')
                                                .shadowRoot.querySelector('#robot-scanner').style.opacity = 0.8;
                                            */
                                        }, 2000);
                                    } else {
                                    }
                                }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
                            });
                            setTimeout(() => {
                                document.querySelector('twinworld-app').shadowRoot.querySelector('.blackbg').style.display = 'none';
                                document.querySelector('twinworld-app').shadowRoot.querySelector('.navigation-control-icon-reset').click();
                                document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'block';
                                setTimeout(() => {
                                    document.querySelector('twinworld-app').shadowRoot.querySelector('.loaderbg').style.display = 'none';
                                }, 3000);
                            }, 3000);
                        }
                    });
            }, 1000);
        }, 1000);
        // eslint-disable-next-line
        setTimeout(() => {
            const twinworldApp = document.querySelector('twinworld-app');
            const twinworldAppObserver = new MutationObserver(function (mutations) {
                mutations.forEach(function (mutation) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'search_term') {
                        const searchTerm = mutation.target.getAttribute('search_term');
                        console.log('search term ::', searchTerm);
                        if (document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper')) {
                            document
                                .querySelector('twinworld-app')
                                .shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper').parentNode.parentNode.style.display = 'block';
                        }
                        document.querySelector('twinworld-app').shadowRoot.querySelector('#cesiumContainer').style.width =
                            'calc(100vw - 64px)';
                        setTimeout(() => {
                            if (document.querySelector('twinworld-app').shadowRoot.querySelector('.cesium-sceneModePicker-button2D')) {
                                document
                                    .querySelector('twinworld-app')
                                    .shadowRoot.querySelector('.cesium-sceneModePicker-button2D')
                                    .click();
                                setTimeout(() => {
                                    document
                                        .querySelector('twinworld-app')
                                        .shadowRoot.querySelectorAll('.cesium-sceneModePicker-visible')[0]
                                        .click();
                                    // document.querySelector('twinworld-app').shadowRoot.querySelector('#modelOnMap').click();
                                    Cesium.createGooglePhotorealistic3DTileset(Cesium.GoogleMaps.defaultApiKey, {
                                        imageryProvider: true,
                                        baseLayerPicker: true,
                                        requestRenderMode: true,
                                        showCreditsOnScreen: true
                                    }).then((tileset) => {
                                        const googlePhotoRealisticTiles = tileset;
                                        window.googlePhotoRealisticTileset = window.viewer.scene.primitives.add(googlePhotoRealisticTiles);
                                    });
                                    /*if (searchTerm.toLowerCase().indexOf('go') >= 0) {
                                        mutation.target.setAttribute('search_response', 'Going to location');
                                    }*/
                                }, 1000);
                            }
                        }, 1000);

                        handleSubmit(searchTerm).then((searchResponse) => {
                            console.log('Search response = ', searchResponse, mutation.target);
                            const prevResponse = mutation.target.getAttribute('search_response');
                            const currResponse = prevResponse === searchResponse ? searchResponse + ' ' : searchResponse;
                            mutation.target.setAttribute('search_response', currResponse);
                        });

                        // console.log('search function', mutation.target.setAttribute('search_response', ''));
                    }
                });
            });
            twinworldAppObserver.observe(twinworldApp, {
                attributes: true
            });
            if (document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper')) {
                document
                    .querySelector('twinworld-app')
                    .shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper').parentNode.parentNode.style.display = 'none';
                document.querySelector('twinworld-app').shadowRoot.querySelector('#leftToolbar .MuiDrawer-paper').style.width = '0';
            }
        }, 3000);
    }, []);

    return (
        <Box sx={{ maxWidth: 1200, flexGrow: 1, zIndex: 1001 }}>
            <AliceCarousel
                mouseTracking
                // items={sceneItems(sceneList)}
                items={sceneItemsDom}
                responsive={responsive}
                controlsStrategy="alternate"
                disableDotsControls="true"
                renderPrevButton={() => {
                    return (
                        <p
                            style={{
                                position: 'absolute',
                                left: '-3px',
                                top: '23px',
                                transform: 'scale(0.8) translateY(-10px)',
                                cursor: 'pointer'
                            }}
                            className="p-4 absolute left-0 top-0"
                        >
                            <img alt="left" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/LeftArrow.png" />
                        </p>
                    );
                }}
                renderNextButton={() => {
                    return (
                        <p
                            style={{
                                position: 'absolute',
                                left: '465px',
                                top: '23px',
                                transform: 'scale(0.8) translateY(-10px)',
                                cursor: 'pointer'
                            }}
                            className="p-4 absolute right-0 top-0"
                        >
                            <img alt="right" src="https://twinworld-component.s3.amazonaws.com/build/utils/lib/RightArrow.png" />
                        </p>
                    );
                }}
            />
        </Box>
    );
}

export default Scenes;
